import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import config from "../config";
import "./ClickToCall.css";
import Error from "../Component/Error";

function ClickToConfirm() {
  const [ui, setUi] = useState("");
  const [customerInfo, setCustomerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setvisible] = useState(true);
  const { randKey, compId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { userToken: token },
        } = await axios.post(`${config.API_URL}/ms/gettoken`);

        const { data } = await axios.get(
          `${config.API_URL}/customer/clicktocallinfo/${randKey}/clicktoconfirm`,
          { headers: { Authorization: token } }
        );

        setvisible(true);
        setCustomerInfo({
          name: data?.CUSTOMER_NAME,
          policyNumber: data?.POLICY,
          custid: data?.custid,
          campid: data?.CAMP_ID,
          phone: data?.PHONE,
          logo: data?.LOGO_NAME,
          image: data?.IMAGE_NAME,
          confirmText: data?.CONFIRM_TEXT,
          confirmButton: data?.CONFIRM_BUTTON,
          confirmColor: data?.CONFIRM_COLOR,
          confirmMessage: data?.CONFIRM_MESSAGE,
          backgroundColor: data?.BACKGROUND_COLOR,
          footerText: data?.FOOTER_TEXT,
        });
      } catch (err) {
        setUi(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [randKey, compId]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  }

  const ResponseMessage = ({ message, customerName }) => {
    const formattedMessage = message.replace("{customer_name}", customerName);

    return (
      <div
        className="card-text"
        dangerouslySetInnerHTML={{ __html: formattedMessage }}
      />
    );
  };

  const onSubmit = async () => {
    try {
      const {
        data: { userToken: token },
      } = await axios.post(`${config.API_URL}/ms/gettoken`);

      const payload = {
        randkey: randKey.substring(3),
        custid: customerInfo?.custid,
        policy_no: customerInfo?.policyNumber,
        status: "CONFIRM_BUTTON_CLICKED",
        campid: customerInfo?.campid,
        today_click: "0",
        phone1: customerInfo?.PHONE,
      };

      const data = await axios.post(
        `${config.API_URL}/customer/postConfirmData`,
        payload,
        { headers: { Authorization: token } }
      );

      if (data?.data?.status == "200") {
        window.alert(
          customerInfo.confirmMessage
            ? customerInfo.confirmMessage
            : "Thank you for confirming"
        );
      }
    } catch (err) {
      setUi(err);
    }
  };

  return (
    <>
      {customerInfo ? (
        <div className="page-wrapper">
          <div className="page-content">
            <div className="container">
              <div
                className="cardCTC clickBoard"
                style={{ borderRadius: "15px" }}
              >
                <div
                  className="card-header text-left  "
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    textAlignLast: "center",
                  }}
                >
                  <img
                    src={customerInfo.logo}
                    alt="Company Logo"
                    className="img-fluid"
                    style={{
                      height: "40px",
                    }}
                  />
                </div>

                <div className="card-body" style={{ padding: "0" }}>
                  <div className="row">
                    <div className="col-12 col-s-12 col-md-6 col-lg-6">
                      <img
                        src={customerInfo?.image}
                        alt="Thank you"
                        style={{
                          width: "100%",
                          maxWidth: "500px",
                          height: "auto",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-12 col-s-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-start">
                      <div
                        className="cardCTC"
                        style={{
                          backgroundColor: customerInfo?.backgroundColor,
                          margin: "calc(5vw + 20px)",
                          width: "auto",
                          maxWidth: "100%",
                          borderRadius: "15px",
                        }}
                      >
                        <div style={{ padding: "20px" }}>
                          <div className="card-text mb-2">
                            <ResponseMessage
                              message={customerInfo?.confirmText}
                              customerName={customerInfo?.name}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            padding: "0px 20px 20px 20px",
                            textAlign: "center",
                          }}
                        >
                          <button
                            className="btn"
                            style={{
                              backgroundColor: customerInfo?.confirmColor,
                              color: "white",
                            }}
                            disabled={!visible}
                            onClick={async () => {
                              await onSubmit();
                              setvisible(false);
                            }}
                          >
                            <p className="card-text">
                              {customerInfo?.confirmButton}
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card-footer text-center pt-4"
                    style={{
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Gabriola",
                        fontSize: "16px",
                      }}
                    >
                      <span style={{ color: "red" }}>*</span>{" "}
                      {customerInfo.footerText}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Error msg={ui.message || "Failed to load customer information."} />
      )}
    </>
  );
}

export default ClickToConfirm;
