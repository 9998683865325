export default function Error404({ msg }) {
  return (
    <section className="vh-100 d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center d-flex align-items-center justify-content-center">
            <div>
              <img
                className="img-fluid w-50"
                src="/images/500.svg"
                alt="404 not found"
              />
              <h1 className="mt-5">
                <span style={{ color: "#19034D", fontWeight: "500" }}>
                  {msg}
                </span>
              </h1>
              <p className="lead my-4"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
