import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../config";
import { errLog, eventLog } from "../utils/function";

function MyPolicy({
  randKey,
  firstLoad,
  setfirstLoad,
  setError,
  exclude,
  navText,
}) {
  const [data, setData] = useState({});
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    axios
      .get(`${config.API_URL}/ms/custData/${randKey}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setData(res.data);
        if (firstLoad) {
          const data = [
            {
              RAND_KEY: randKey,
              CUST_ID: res.data.CUST_ID,
              CAMP_ID: res.data.CAMP_ID,
              STATUS: "Policy Component Loaded.",
            },
          ];

          eventLog(data);
          setfirstLoad(false);
        }
      })
      .catch((err) => {
        setError("Your Session Expired. Please login.");

        if (firstLoad) {
          const data = {
            sNo: "9999",
            taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/custData/${randKey}`,
            errMessage: {
              Message: err.message,
            },
            errRemarks: `ERROR OCCURED WHILE FETCHING cUSTDATA FOR ${randKey}`,
          };

          errLog(data);
          setfirstLoad(false);
        }
      });
  }, []);
  const arr = Object.keys(data);
  return (
    <>
      <div className="card mt-4">
        <div
          className="card-header"
          style={{
            borderTopLeftRadius: "0.95rem",
            borderTopRightRadius: "0.95rem",
          }}
        >
          <h4>{navText}</h4>
        </div>
        <div className="card-body" style={{ textAlign: "left" }}>
          <div className="row policy">
            {arr.length > 0 &&
              arr.map((item, index) => {
                if (exclude.indexOf(item) === -1) {
                  return (
                    <div
                      key={`policy${index}`}
                      className="col-md-4 col-sm-4 col-xs-6 col-lg-4 pb-4"
                    >
                      <h6>{item}</h6>
                      <p>{typeof data[item] !== "object" ? data[item] : "_"}</p>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPolicy;
