import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import ErrorBoundary from "./ErrorBoundry";
import "./App.css";
import Error from "./Component/Error";
import Index from "./Pages/Index";
import Login from "./dashboard/pages/AdminLogin";
import Dashbboard from "./dashboard/pages/Dashbboard";
import { useSelector } from "react-redux";
import ClickToCall from "./Pages/ClickToCall";
import ClickToConfirm from "./Pages/ClickToConfirm";
import Feedback from "./Pages/Feedback";
import Landing from "./Pages/landingPage/Landing";
import Upload from "./Pages/Upload";

function App() {
  const { UserToken } = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      document.onkeydown = function (e) {
        if (e.keyCode == 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
          return false;
        }
      };
      document
        .getElementsByTagName("html")[0]
        .setAttribute("oncontextmenu", "return false");
    }
  }, []);

  return (
    <>
      <Switch>
        <Route
          path="/upload/:randstr"
          render={() => (
            <ErrorBoundary>
              <Upload />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/error"
          render={() => (
            <ErrorBoundary>
              <Error />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/login"
          render={() => (
            <ErrorBoundary>
              <Login />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/feedback/:randstr"
          render={() => (
            <ErrorBoundary>
              <Feedback />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/landing/:randstr"
          render={() => (
            <ErrorBoundary>
              <Landing />
            </ErrorBoundary>
          )}
        />
        {UserToken && (
          <Route
            path="/dashboard"
            render={() => (
              <ErrorBoundary>
                <Dashbboard />
              </ErrorBoundary>
            )}
          />
        )}
        <Route
          path="/click/:randKey"
          render={() => (
            <ErrorBoundary>
              <ClickToCall />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/confirm/:randKey"
          render={() => (
            <ErrorBoundary>
              <ClickToConfirm />
            </ErrorBoundary>
          )}
        />
        <Route
          path="/:randKey"
          render={() => (
            <ErrorBoundary>
              <Index />
            </ErrorBoundary>
          )}
        />
        <Route path="*">
          <Redirect to="/error" />
        </Route>
      </Switch>
    </>
  );
}

export default App;
