import React from "react";

function Footer({ ui }) {
  const ResponseMessage = ({ message, style }) => {
    return <p style={style} dangerouslySetInnerHTML={{ __html: message }} />;
  };

  return (
    <footer className="footer d-flex flex-column flex-md-row" style={ui.style}>
      <div>
        <ResponseMessage message={ui.text.value} style={ui.text.style} />
      </div>
    </footer>
  );
}

export default Footer;
