import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../config";
import { errLog, eventLog } from "../utils/function";

function WhyRenew({
  product_code,
  camp_id,
  campName,
  whyRenewFirst,
  setWhyRenewFirst,
  token,
  cust_id,
  randkey,
}) {
  const [ui, setUi] = useState("");
  useEffect(() => {
    axios
      .get(`${config.API_URL}/ms/productdata/${product_code}/${campName}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setUi(res.data);
        if (whyRenewFirst) {
          const data = [
            {
              RAND_KEY: randkey,
              CUST_ID: cust_id,
              CAMP_ID: camp_id,
              STATUS: "Why Renew Component Loded.",
            },
          ];

          eventLog(data);
          setWhyRenewFirst(false);
        }
      })
      .catch((err) => {
        const data = {
          sNo: "9999",
          taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/custData/${randkey}`,
          errMessage: {
            Message: err.message,
          },
          errRemarks: `ERROR OCCURED WHILE FETCHING cUSTDATA FOR ${randkey}`,
        };

        errLog(data);
      });
  }, []);
  return (
    <div>
      {ui && ui[0] && ui[0].PRODUCT_NAME && (
        <div className="card mt-4">
          <div className="card-body">
            <div dangerouslySetInnerHTML={{ __html: ui[0].PRODUCT_HTML }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default WhyRenew;
